import React, { Component } from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"

import '../scss/a-propos.scss'

import imgHeader from '../images/portrait.png'

import skillWebdesign from '../svg/skills-webdesign.svg'
import skillUI from '../svg/skills-mobile.svg'
import skillIntegration from '../svg/skills-integration.svg'
import skillGestion from '../svg/skills-gestion.svg'
import skillSoftware from '../svg/skills-software.svg'
import skillLanguage from '../svg/skills-language.svg'
import skillHuman from '../svg/skills-human.svg'

import skillDesign from '../svg/skills-design.svg'
import skillPlanning from '../svg/skills-planning.svg'
import skillCom from '../svg/skills-com.svg'
import skillCM from '../svg/skills-cm.svg'
import skillIllustration from '../svg/skills-illustration.svg'
import skillVideo from '../svg/skills-video2.svg'

import experience_scene1 from '../svg/experience_scene1.svg'
import experience_scene2 from '../svg/experience_scene2.svg'
import experience_scene3 from '../svg/experience_scene3.svg'
import experience_scene4 from '../svg/experience_scene4.svg'
import experience_scene5 from '../svg/experience_scene5.svg'

export default class Apropos extends Component {

  render() {
    return (
      <Layout mainClass="page__aPropos">
        <SEO title="A propos"/>

        <section className="block__introduction">
          <div className="wrapper">
            <div className="introduction__leftPart">
              <h1 className="introduction__title">
                Je suis designer d'interfaces spécialisée dans le web et le mobile.
              </h1>

              <p className="block__description">
                J'aime être présente à toutes les étapes du processus de création : de l'idée à la conception, du prototype au développement jusqu'à la communication. 
                <br />Mon objectif : créer des interfaces aussi humaines et accessibles que possible. 
              </p>
            </div>

            <div className="introduction__rightPart">
              <img src={imgHeader} alt="Kary LE" />
            </div>
          </div>
        </section>

        <section className="block__skills">
          <div className="wrapper">
            <div className="block__title">
              Mes compétences
            </div>
            
            <div className="block__skill-line">
              <div className="block__skill">
                <div className="skill__title">
                  <img src={skillWebdesign} alt="Webdesign" />
                  <span>UX/UI design</span>
                </div>
                <div className="skill__list">
                  <ul>
                    <li>Wireframing</li>
                    <li>Création de maquettes graphiques orientées utilisateurs</li>
                    <li>Prototypage</li>
                    <li>Notions en accessibilité et normes WAI</li>
                    <li>Notions en ergonomie des interfaces</li>
                  </ul>
                </div>
              </div>

              <div className="block__skill">
                <div className="skill__title">
                  <img src={skillUI} alt="UI Design" />
                  <span>Web</span>
                </div>
                <div className="skill__list">
                  <ul>
                    <li>Audit de sites web (SEO, accessibilité, UX/UI design, éditorial)</li>
                    <li>Intégration de contenu</li>
                    <li>Gestion des tickets</li>
                  </ul>
                </div>
              </div>

              <div className="block__skill">
                <div className="skill__title">
                  <img src={skillIntegration } alt="Intégration web" />
                  <span>Intégration web</span>
                </div>
                <div className="skill__list">
                  <ul>
                    <li>Langages HTML5 & CSS3</li>
                    <li>Création et gestion d’un thème Wordpress</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="block__skill-line">
              <div className="block__skill">
                <div className="skill__title">
                  <img src={skillIllustration} alt="Illustration" />
                  <span>Illustration</span>
                </div>
                <div className="skill__list">
                  <ul>
                    <li>Création d'illustrations et icônes à partir d'un brief pour le web et le print</li>
                  </ul>
                </div>
              </div>

              <div className="block__skill">
                <div className="skill__title">
                  <img src={skillVideo} alt="Audiovisuel" />
                  <span>Audiovisuel</span>
                </div>
                <div className="skill__list">
                  <ul>
                    <li>Livestreaming sur Twitch avec Streamlabs OBS</li>
                    <li>Notions en animation avec After Effect</li>
                  </ul>
                </div>
              </div>

              <div className="block__skill">
                <div className="skill__title">
                  <img src={skillSoftware} alt="Logiciels favoris" />
                  <span>Logiciels favoris</span>
                </div>
                <div className="skill__list">
                  <ul>
                    <li>Pack Adobe (Photoshop, Indesign, Illustrator, After Effects)</li>
                    <li>Adobe XD</li>
                    <li>Figma</li>
                    <li>Clip Studio Paint, Procreate</li>
                    <li>Sketch (notions)</li>
                  </ul>
                </div>
              </div>            
            </div>

            <div className="block__skill-line">
              <div className="block__skill">
                  <div className="skill__title">
                    <img src={skillGestion} alt="Gestion de projet" />
                    <span>Gestion de projet</span>
                  </div>
                  <div className="skill__list">
                    <ul>
                      <li>Gestion de projet avec Trello ou ClickUp</li>
                    </ul>
                  </div>
                </div>

                <div className="block__skill">
                  <div className="skill__title">
                    <img src={skillLanguage} alt="Langues" />
                    <span>Langues</span>
                  </div>
                  <div className="skill__list">
                    <ul>
                      <li>Français (langue maternelle)</li>
                      <li>Anglais (lu, parlé, écrit)</li>
                    </ul>
                  </div>
                </div>  
              <div className="block__skill">
                <div className="skill__title">
                  <img src={skillHuman} alt="Qualités humaines" />
                  <span>Qualités humaines</span>
                </div>
                <div className="skill__list">
                  <ul>
                    <li>Polyvalente</li>
                    <li>Autonome</li>
                    <li>Organisée</li>
                    <li>Curieuse</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="block__experiences" ref="blockExperiences">
          <div className="wrapper">
            <div className="block__title">Mes expériences et diplômes</div>
            <div className="experiences__list" ref="experiencesTimeline">
              {/* <div className="experience" data-id="1">
                <div className="experience__date">2014</div>
                <div className="experience__title">Webdesigner</div>
                <div className="experience__short-description">Stagiaire chez Moody Communication</div>
              </div> */}

              {/* <div className="experience" data-id="2">
                <div className="experience__date">2014-2016</div>
                <div className="experience__title">CM & Webdesigner</div>
                <div className="experience__short-description">Bénévole pour Infinite RPG</div>
              </div> */}

              <div className="experience" data-id="3">
                <div className="experience__date">2016-2018</div>
                <div className="experience__title">DUT Information-Communication</div>
                <div className="experience__short-description">IUT Robert Schuman à Strasbourg</div>
              </div>

              <div className="experience" data-id="4">
                <div className="experience__date">2018-2019</div>
                <div className="experience__title">LP Métiers du numérique<br />Parcours Graph</div>
                <div className="experience__short-description">IUT Haguenau</div>
              </div>

              <div className="experience" data-id="5">
                <div className="experience__date">2017-2019</div>
                <div className="experience__title">CM & Webdesigner</div>
                <div className="experience__short-description">Apprenti chez Destination Haute Alsace</div>
              </div>

              <div className="experience" data-id="6">
                <div className="experience__date">2020-2021</div>
                <div className="experience__title">Illustratrice digitale</div>
                <div className="experience__short-description">Auto-entrepreneur</div>
              </div>

              <div className="experience" data-id="7">
                <div className="experience__date">2021-2022</div>
                <div className="experience__title">Webdesigner & Graphiste</div>
                <div className="experience__short-description">Conseil de l'Europe</div>
              </div>
            </div>

            <div className="experience__toShow">
              {/* <div className="experience__selected" data-id="1">
                <div className="selected__leftPart">
                  <div className="selected__title">
                    Webdesigner
                  </div>
                  <ul className="selected__list">
                    <li>
                      <img src={skillWebdesign} alt="Webdesign" />
                      <div className="text">
                        <strong>Webdesign</strong> : Création de bannières et création de newsletter
                      </div>
                    </li>
                    <li>
                      <img src={skillIntegration} alt="Intégration HTML/CSS" />
                      <div className="text">
                        <strong>Intégration HTML/CSS</strong> du site Wordpress Âme du Chateau
                      </div>
                    </li>

                    <li>
                      <img src={skillGestion} alt="Deux semaines de stage" />
                      <div className="text">
                        Deux semaines de stage
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="selected__rightPart">
                  <img src={experience_scene1} alt="Webdesigner @ Moody Communication"/>
                </div>
              </div> */}

              {/* <div className="experience__selected" data-id="2">
                <div className="selected__leftPart">
                  <div className="selected__title">
                    CM & Webdesigner
                  </div>
                  <ul className="selected__list">
                    <li>
                      <img src={skillWebdesign} alt="Webdesign" />
                      <div className="text">
                        <strong>Webdesign</strong> : Création de bannières et d’illustrations pour les réseaux sociaux
                      </div>
                    </li>
                    <li>
                      <img src={skillIntegration} alt="Intégration HTML/CSS" />
                      <div className="text">
                        <strong>Intégration HTML/CSS</strong> des pages du réseau social desktop et mobile
                      </div>
                    </li>
                    <li>
                      <img src={skillCM} alt="Intégration HTML/CSS" />
                      <div className="text">
                        <strong>Community Management</strong> modération du groupe Facebook, animation de la page et du réseau social Infinite RPG, participation aux débats sur Youtube, promotion du projet
                      </div>
                    </li>
                    <li>
                      <img src={skillGestion} alt="Deux ans de bénévolat" />
                      <div className="text">
                        2014 à 2016 : Deux ans de bénévolat
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="selected__rightPart">
                  <img src={experience_scene2} alt="Webdesigner @ Moody Communication"/>
                </div>
              </div> */}
            
              <div className="experience__selected" data-id="3">
                <div className="selected__leftPart">
                  <div className="selected__title">
                    DUT Information & Communication
                  </div>
                  <ul className="selected__list">
                    <li>
                      <img src={skillCom} alt="Webdesign" />
                      <div className="text">
                        <strong>Communication</strong> : Base en théorie de l’info-com, expression orale et écrite, stratégie de communication et droit de l’info-com
                      </div>
                    </li>
                    <li>
                      <img src={skillIntegration} alt="Intégration HTML/CSS" />
                      <div className="text">
                        <strong>Web</strong> : Base et notions en référencement, HTML5 & CSS3 et MySQL + PHP
                      </div>
                    </li>

                    <li>
                      <img src={skillGestion} alt="Deux ans" />
                      <div className="text">
                        2016 - 2018 : Deux ans 
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="selected__rightPart">
                  <img src={experience_scene3} alt="DUT Information &amp; Communication"/>
                </div>
              </div>
            
              <div className="experience__selected" data-id="4">
                <div className="selected__leftPart">
                  <div className="selected__title">
                    LP Métiers du numérique GRAPH
                  </div>
                  <ul className="selected__list">
                    <li>
                      <img src={skillDesign} alt="Webdesign" />
                      <div className="text">
                        <strong>Design</strong> : création d’une identité graphique, typographie et infographie, webdesign (desktop et mobile), graphisme print et web
                      </div>
                    </li>
                    <li>
                      <img src={skillIntegration} alt="Intégration HTML/CSS" />
                      <div className="text">
                        <strong>Web</strong> : Wordpress, UX/UI, HTML5 & CSS3 + responsive, notions de référencement
                      </div>
                    </li>
                    <li>
                      <img src={skillVideo} alt="Motion Design" />
                      <div className="text">
                        <strong>Vidéo</strong> : Motion design basique et animation (DUIK BASSEL)
                      </div>
                    </li>
                    <li>
                      <img src={skillGestion} alt="Un an" />
                      <div className="text">
                        2018 - 2019 : Un an
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="selected__rightPart">
                  <img src={experience_scene4} alt="LP Métiers du numérique GRAPH"/>
                </div>
              </div>

              <div className="experience__selected" data-id="5">
                <div className="selected__leftPart">
                  <div className="selected__title">
                    CM & Webdesigner
                  </div>
                  <ul className="selected__list">
                    <li>
                      <img src={skillWebdesign} alt="Webdesign" />
                      <div className="text">
                        <strong>Webdesign</strong> : Refonte du site web Destination Haute Alsace, création du site vitrine nordic.alsace sur Adobe Photoshop & Adobe XD
                      </div>
                    </li>
                    <li>
                      <img src={skillIntegration} alt="Intégration HTML/CSS" />
                      <div className="text">
                        <strong>Intégration HTML/CSS</strong> du nouveau site web Destination Haute Alsace et du site vitrine nordic.alsace
                      </div>
                    </li>
                    <li>
                      <img src={skillDesign} alt="Design" />
                      <div className="text">
                        <strong>Design</strong> : création de supports de communication print
                      </div>
                    </li>
                    <li>
                      <img src={skillCM} alt="CM" />
                      <div className="text">
                        <strong>Community Management</strong> : gestion des réseaux sociaux (Facebook, Twitter et Instagram) de Destination Haute Alsace et Gîtes de France Haute Alsace
                      </div>
                    </li>
                    <li>
                      <img src={skillPlanning} alt="Gestion de projet" />
                      <div className="text">
                        <strong>Gestion de projet</strong> : Planning de publication et gestion des projets avec Clickup
                      </div>
                    </li>
                    <li>
                      <img src={skillGestion} alt="Deux semaines de stage" />
                      <div className="text">
                        2017 à 2019 en apprentissage
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="selected__rightPart">
                  <img src={experience_scene2} alt="Webdesigner @ Moody Communication"/>
                </div>
              </div>

              <div className="experience__selected" data-id="6">
                <div className="selected__leftPart">
                  <div className="selected__title">
                    Illustratrice digitale
                  </div>
                  <ul className="selected__list">
                    <li>
                      <img src={skillIllustration} alt="Illustration" />
                      <div className="text">
                        <strong>Illustration</strong> : Illustrations personnalisées avec Clip Studio Paint, Procreate ou Adobe Photoshop sur brief du client (B2C et B2B)
                      </div>
                    </li>
                    <li>
                      <img src={skillCM} alt="Community management" />
                      <div className="text">
                        <strong>Community management</strong> Elaboration de la stratégie de communication, gestion des réseaux sociaux (Facebook, Twitter, Instagram) et création de publicités payantes
                      </div>
                    </li>
                    <li>
                      <img src={skillGestion} alt="Gestion" />
                      <div className="text">
                        <strong>Gestion</strong> : Elaboration de devis, gestion de projet et comptabilité
                      </div>
                    </li>
                    <li>
                      <img src={skillVideo} alt="Vidéo" />
                      <div className="text">
                        <strong>Vidéo</strong> : Livestreaming sur Twitch, création d’un branding, enregistrement et montage du process d’illustration pour YouTube
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="selected__rightPart">
                  <img src={experience_scene5} alt="Webdesigner @ Moody Communication"/>
                </div>
              </div>

              <div className="experience__selected" data-id="7">
                <div className="selected__leftPart">
                  <div className="selected__title">
                    Webdesigner / Graphiste
                  </div>
                  <ul className="selected__list">
                    <li>
                      <img src={skillWebdesign} alt="Web" />
                      <div className="text">
                        <strong>Web</strong> : Création de sites web avec le CMS Liferay, intégration de contenu, audit de sites web (SEO, accessibilité, UX/UI)
                      </div>
                    </li>
                    <li>
                      <img src={skillUI} alt="UX/UI Design" />
                      <div className="text">
                        <strong>UX/UI design</strong> Consulting UX, création de maquettes graphiques orientée utilisateur, accessibilité en conforme avec les normes WAI
                      </div>
                    </li>
                    <li>
                      <img src={skillIllustration} alt="Graphisme et illustration" />
                      <div className="text">
                        <strong>Graphisme et illustration</strong> : création de visuels pour les réseaux sociaux et sites web, création d’illustrations animées avec After Effect pour la promotion de podcast
                      </div>
                    </li>
                    <li>
                      <img src={skillGestion} alt="Gestion" />
                      <div className="text">
                        <strong>Gestion de projet</strong> : Planning et contact avec les clients, gestion de projet personnel avec Clickup
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="selected__rightPart">
                  <img src={experience_scene1} alt="Webdesigner @ Moody Communication"/>
                </div>
              </div>
            </div>
          </div>

          <div className="experience__extra">
            <div className="wrapper">
              <div className="extra__title">Autres :</div>
              <ul>
                <li>2014 - Stage Webdesigner chez Moody Communication</li>
                <li>2014-2016 - CM & Webdesigner bénévole chez Infinite RPG</li>
              </ul>
            </div>
          </div>
        </div>
      </Layout>
    )
  }

  componentDidMount() {
    // Blocks experiences : au click on change de slide.
    const blockExperiences = this.refs.blockExperiences
    const blockParent = blockExperiences.querySelector('.experience__toShow')
    const experiencesTriggers = this.refs.experiencesTimeline.querySelectorAll('.experience')

    experiencesTriggers.forEach((trigger) => {
      trigger.addEventListener('click', (e) => {
        let currentElem = this.refs.experiencesTimeline.querySelector('.experience--current')
        
        if (currentElem) {
          currentElem.classList.remove('experience--current')
        }
        
        trigger.classList.add('experience--current')

        const id = trigger.getAttribute('data-id')

        const blockToShow = this.refs.blockExperiences.querySelector('.experience__selected[data-id="' + id + '"]')
        let blockHeight = blockToShow.offsetHeight
        blockParent.style.height = blockHeight + 'px'

        let currentElemLong = this.refs.blockExperiences.querySelector('.experience__selected.active')

        if (currentElemLong) {
          currentElemLong.classList.remove('active')
        }

        blockToShow.classList.add('active')
      })
    })

    // Blocks experiences : au chargement, le premier block doit avoir la bonne taille.
    setTimeout(() => {
      const firstBlockExp = blockExperiences.querySelector('.experience:first-of-type')
      
      if (firstBlockExp) {
        firstBlockExp.click()
      }
    }, 1000)

    let cancelTimeout

    window.addEventListener('resize', () => {
      if (window.innerWidth > 480) {
        window.clearTimeout(cancelTimeout)
        cancelTimeout = setTimeout(() => {
          // Recalc current block
          if (this.refs.experiencesTimeline) {
            let currentBlock = this.refs.experiencesTimeline.querySelector('.experience--current')
            if (currentBlock) {
              currentBlock.click()
            }
          }
        }, 200)
      }
    })
  }
}
